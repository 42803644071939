@mixin mq_max_768 {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: 768px) {
    @content;
  }
}
/* ======================================================================
 header
====================================================================== */
header.common_header,
header.common_header.top_ver {
  @include sp {
    display: block !important;
    opacity: 1 !important;
    position: fixed;
    top: 0;
    background: #fff !important;
    height: 66px;
  }
}

.common_header.top_ver .header_logo img {
  @include sp {
    opacity: 1;
  }
}
@include sp {
  .sub_main_vis {
    margin-top: 66px;
  }

  .common_header {
    height: 66px;

    .header_logo {
      height: auto;
      margin: 0;
      text-align: left;
      margin: 13px 10px 0;

      img {
        height: auto;
        margin: 0;
      }
    }
  }

  .common_header ul.txt._shadow {
    padding-bottom:: 64px;
  }

  .sp-header2021 {
    .inner.menu {
      .top-memu {
        display: flex !important;

        .link01 {
          height: 66px;
          width: 33%;
          border-left: 1px solid #EDEDED;

          .box01,
          .box02 {
            p {
              text-align: center;
              font-size: 13px;
              font-weight: bold;
              color: #5A5249;
              font-family: "Noto Sans japanese", sans-serif;
              letter-spacing: 1.04px;
              padding-top: 41px;
            }
          }

          .box01 {
            background: url("../img/p_top/sp/icon_stomach.svg") no-repeat center 10px;
          }

          .box02 {
            background: url("../img/p_top/sp/icon_colon.svg") no-repeat center 10px;
          }
        }
      }
    }

    .bottom-memu {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 66px;
      background: #fff;
      z-index: 100;

      .block-01 {
        display: flex;

        .menu-mobile {
          position: relative;
          right: inherit;
          top: inherit;
          background: #5A5249;
          height: 66px;
          width: 65px;

          &::after {
            background: url("../img/p_top/sp/btn_menu_white.png") no-repeat;
            background-size: contain;
            width: 35px;
            height: 35px;
            float: none;
            position: absolute;
            left: 15px;
            top: 17px;
          }
        }

        .as {
          width: 76px;
          height: 66px;
          background: url("../img/p_top/sp/as.svg") no-repeat center center;
        }

        .tel,
        .web {
          width: 33%;
          height: 66px;
          //display: none;
        }

        .web {
          border-left: 1px solid #9F9C98;
          background: #FFF8CB url("../img/p_top/sp/web.svg") no-repeat center center;
        }

        .tel {
          border-left: 1px solid #5A5249;
          background: #FFE300 url("../img/p_top/sp/tel.svg") no-repeat center center;
        }

        &.active {
          .menu-mobile {
            &::after {
              background: url("../img/p_top/sp/btn_menu_on.png") no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }

    &.active {
      .inner.menu {
        .header_logo {
          margin: 0 auto;
          text-align: center;
          padding: 10px;
        }

        .link01 {
          display: none;
        }
      }
    }
  }
}

body {
  .mokuji_btn {
    @include sp {
      bottom: 75px;
      right: 10px;
      left: inherit;
      transform: inherit;
    }
  }
}

.top .contact_btn_area {
  display: none !important;
}

.fix-01 {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9999;
  @include sp {
    display: none;
  }

  a {
    display: block;
  }

  img {
    transition: all 0.3s linear;

    &:hover {
      opacity: 0.7;
    }
  }

  .left {
    position: absolute;
    left: 0;
    bottom: 10px;

    a {
      height: 180px;
      margin-left: -9px;

      &:nth-child(1) {
        //margin-bottom: 10px;
        //margin-top: 65px;
      }
    }
  }

  .right {
    position: absolute;
    right: 0;
    bottom: 20px;

    a {
      height: 202px;
      margin-right: -9px;

      &:nth-child(1) {
        margin-bottom: 10px;
        margin-top: 0;
      }
    }
  }
}

.pagelink_map_colonoscopy_large.a2021 .inner {
  .img_wrap {
    text-align: left;

    a {
      &:last-child {
        margin: 30px 0 0;
        //display: block;
        @include sp {
          margin: 10px 0 0;
          display: inherit;
        }
      }
    }
  }
}

.common_header ul.megamenu_content li.inner ul li.list_six:nth-child(13) {
  margin: 0;
}

.sec_foot.new20220615 {
  margin-bottom: 115px;
  @include sp {
    margin-bottom: 60px;
  }
}

.sec_foot.new20220615 .inner .digestive__cv-area {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include sp {
    display: block;
  }
}

.banner_foot {
  width: 100%;
  max-width: 680px;
  margin: 0 auto 70px;
  @include sp {
    margin: 0 auto 35px;
  }

  img {
    display: block;
    margin: 0 auto;
    transition: all 0.3s linear;
    max-width: 100%;
    @include sp {
      max-width: 100%;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.sec_foot.new20220615a {
  margin-bottom: 40px;
  @include sp {
    margin-bottom: 35px;
  }
}

.sec_foot.new20220615a .inner {
  width: 100%;

  .digestive__cv-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include sp {
      display: block;
    }

    img {
      width: 60%;
      @include sp {
        width: auto;
      };
    }

    .digestive__cv-area-tel,
    .digestive__cv-area-web {
      height: 140px;
      @include sp {
        height: 200px;
      }
    }
  }

  .banner_foot {
    width: 100%;
    margin-bottom: 70px;
    max-width: 100%;
    @include sp {
      margin-bottom: 35px;
      width: 100%;
      max-width: 100%;
    }

    img {
      display: block;
      margin: 0 auto;
      transition: all 0.3s linear;
      max-width: 100%;
      width: 100%;
      @include sp {
        max-width: 100%;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.link01 {
  color: #5a5148;
  text-decoration: underline;

  &:active,
  &:hover,
  &:link,
  &:visited {
    color: #5a5148;
  }
}

.bold02 {
  font-family: "Noto Sans japanese", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #5a5249;
}
//
//
//
//
//
//
//
//
//
//
//
//
//